.aboutGoalCardContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  height: 474px;
  width: 1280px;
  user-select: none;
}

.illustration {
  position: absolute;
  left: -150px;
  top: 135px;
  user-select: none;
}

.goalInfoHeader {
  position: absolute;
  height: 43px;
  top: 45px;
  left: 227px;
}

.goalInfoType {
  position: absolute;
  height: 30px !important;
  top: 91px;
  left: 227px;
  font-weight: normal !important;
}

.goalDivider {
  position: absolute;
  top: 136px;
  left: 221px;
  user-select: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.todaysMoney {
  position: absolute;
  height: 30px;
  top: 156px;
  left: 221px;
  font-weight: bold !important;
  line-height: 26px !important;
}

.todaysMoneyAmount {
  position: absolute;
  top: 186px;
  left: 221px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 46px;
  width: 240px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #0c3957;
  background: #f5f8fa;
  border-radius: 4px;
  user-select: none;
}

.todaysMoneyAmountText,
.todaysMoneyAmountText:focus {
  padding-left: 20px;

  display: flex;
  align-items: center;
}

.timeHorizon {
  position: absolute;
  top: 252px;
  left: 221px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
}

.timeHorizonIncrementor {
  position: absolute;
  top: 290px;
  left: 202px;
}

.importantHeader {
  position: absolute;
  height: 26px !important;
  width: 240px;
  top: 358px;
  left: 222px;
  font-weight: bold !important;
  line-height: 26px !important;
}

.importantButtons {
  position: absolute;
  top: 399px;
  left: 222px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.todaysMoneyInputCurrency {
  position: relative;
  top: 195px;
  left: 62px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.todaysmoneyinput,
.todaysmoneyinput:focus,
.todaysmoneyinput::selection {
  position: absolute;
  top: 185px;
  left: 230px;
  padding-top: 0.5px;
  padding-left: 31px;
  width: 210px;
  height: 46px;
  border: 0 none transparent;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  background: #f5f8fa;
  outline: none;
}

input,
textarea {
  background-color: #f5f8fa;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noGoals {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  width: 377px;
  border-radius: 8px;
  left: 265px;
  top: 100px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.15);
}

.noGoalsIllustration {
  top: 90px;
}

.noGoalsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-top: 26px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
}

.noGoalsText {
  padding: 30px;
  margin-top: -19px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  text-align: center;
}
