.goalSummaryCardMainContainer {
  height: 268px;
  display: flex;
  align-items: flex-start;
  padding: 0px;
  margin-right: 20px;
  width: 387px;
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}

.summaryCardBody {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 387px;
  height: 268px;
}

.summaryCardHeader {
  width: 357px;
  margin-left: 25px;
  padding-top: 18px;
  height: 78px;
  display: flex;
}

.summaryCardHeaderColumn {
  flex-direction: column;
}

.summaryCardHeaderBox {
  flex-direction: row;
}

.summaryCardHeaderBoxInflationLinked {
  position: relative;
  left: -57px;
  top: 13px;
}

.inflationLinkedLabel {
  position: absolute;
  left: -88px;
  top: 36px;
  font-size: 14px;
}

.goalSummaryDivider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  position: relative;
  top: 10px;
}

.inflationLinked {
  top: -60px;
}

.goalSummaryTypeContainer {
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0em !important;
  text-align: left;
  margin-bottom: -10px !important;
  background: #ffffff;
}

.summaryCardMiddle {
  width: 357px;
  margin-left: 25px;
  padding-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.summaryCardMiddleContainer {
  margin-top: -14px;
  padding-right: 15px;
}

.goalSummaryRequiredTodayLabel {
  font-weight: bold !important;
  line-height: 26px !important;
}

.goalSummaryRequiredTodayContainer {
  line-height: 26px !important;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.summaryCardEnd {
  display: flex;
  align-items: flex-start;
  width: 357px;
  margin-left: 25px;
  padding-top: 27px;
}

.summaryCardEndLeft {
  width: 175px;
}

.summaryCardEndRight {
  display: flex;
  align-items: center;
  width: 175px;
}

.summaryCardEndIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.iconLabel {
  width: 50px;
}

.editIcon {
  margin-top: 10px;
}

.asterisk {
  font-weight: bold;
  display: inline-block;
  margin: 0px;
}

.inflationLinkedSwitch {
  position: relative;
}

.goalDetailsMiddle {
  display: inline;
}

.goalDetailsMiddleField {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 2px;
}

.goalSummaryRequiredTodayLabelDetails {
  margin-right: 38px !important;
  width: 144px;
  outline: none;
}

.yearsDetails,
.yearsDetails:focus {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #0c3957;
  border: none;
  outline: none;
  margin-top: -8px;
  margin-left: 2px;
}

.amountDetails {
  width: 130px !important;
}

.goalDetailsTextArea,
.goalDetailsTextArea:focus,
.goalDetailsTextArea::placeholder {
  width: 320px;
  height: 88px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #0c3957 !important;
}

textarea {
  resize: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
}

.yearsText {
  margin-top: -1px;
  margin-left: -10px;
}
