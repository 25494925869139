.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
}

.loginCard {
  width: 500px;
  /* height: 409px; */
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 13vh;
}

.ammoniteLogoContainer {
  position: realtive;
  margin-bottom: 3vh;
}

.logoText {
  margin-left: 10px;
}

.loginHeader {
  margin-top: 50px !important;
  margin-left: 100px !important;
  margin-bottom: 30px !important;
}

.unameIcon,
.pwordIcon {
  user-select: none;
}

.loginInput,
.loginInput:focus,
.loginInput:active {
  height: 46px;
  width: 300px;
  background: #f5f8fa;
  border-radius: 4px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #7a7a7a;
  padding: 0px 30px;
  box-sizing: border-box;
  border: none;
}

.email .password {
  margin-top: 31px;
}

.loginButtonContainer {
  margin-top: 45px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
}

.unameContainer {
  display: block;
  height: 100px;
  margin-left: 100px;
  margin-bottom: 6px;
}

.pwordContainer {
  display: block;
  height: 63px;
  margin-left: 100px;
}

.unameLabelContainer {
  display: flex;
}

.unameLabel,
.pwordLabel {
  margin-left: 10px;
  color: #232323;
}

.emailWarning {
  display: flex;
  align-items: center;
  margin-bottom: -35.45px;
}

.passwordWarning {
  display: flex;
  align-items: center;
  margin-top: -30px;
  margin-left: -25px;
}

.emailWarningText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #b40125;
  padding: 5px;
}

.redWarningBorder {
  border: 1px solid #b40125;
  border-radius: 4px;
}

.showIcon {
  position: relative;
  top: -35px;
  left: 258px;
  width: 30px;
}

.loginInfoText {
  position: relative;
  width: 75%;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #232323;
}

.loginForgotPassword {
  display: flex;
  justify-content: center;
  margin-top: -32px;
  margin-bottom: 13px;
  font-weight: bold;
  color: #575a89;
  cursor: pointer;
}

.loginBlockInputPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.forgottenPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 430px;
  height: 410px;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgb(0 0 0 / 55%);
  z-index: 5;
}

.forgotPasswordText {
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0em;
  padding: 30px;
  color: #0c3957;
  width: 370px;
}

.resetPasswordButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetPasswordEmail {
  margin-left: 10px;
}

.forgotPasswordEmail {
  display: flex;
  align-items: center;
}
