.infoContainer {
  display: "flex";
  flex-direction: "column";
  align-items: "flex-start";
  margin-left: 45px;
  width: "100vw";
  height: 566px;
  width: 1235px;
}

.switchPageContainer {
  padding-top: 26px;
}

.personCards {
  display: flex;
}

.addPartner {
  position: absolute;
  width: 36px;
  height: 36px;
  left: 614px;
  top: 313px;
}

.addPartnerText {
  position: absolute;
  height: 30px;
  left: 664px;
  right: 426px;
  top: 312px;
}

.extraInfo {
  height: 45px;
}

.warningBox {
  position: absolute;
  top: 646px;
  right: -36px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warningText {
  margin-left: -20px;
  width: 170px;
  color: #b40125;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
